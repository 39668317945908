@font-face {
	font-family: "Cera normal";
	font-weight: "100";
	font-size: "24px";
	src: url("fonts/Cera/Cera Pro Medium.otf");
}

.book-item {
	position: relative;
}

.book-item-popup {
	position: absolute;
	top: 2%;
	left: 10%;
	z-index: 100;
	width: 80%;
	min-height: 230px;
	padding: 12px 10px;
	border-radius: 7px;
	display: none;
	color: #4caf50;
}

.book-item:hover .book-item-popup {
	display: block;
}

.book-item-button {
	display: block;
	padding: 1px 2px;
	width: 77%;
	margin: 0px auto;
	border: 1px solid #4caf50;
	text-align: center;
	border-radius: 15px;
	font-size: 14px;
	margin-top: 5px;
}

.book-item-button:hover {
	border-color: #2f8732;
	border-width: 1.5px;
	color: #2f8732;
}

.filter-root {
	display: flex;
	margin-top: 15px;
	margin-bottom: 15px;
}

.filter-item {
	margin-right: 10px;
}

.home-slider {
	min-height: 50vh;
}

.slider-details {
	width: fit-content;
	/* padding-left: 100px; */
	padding-top: 50px;
	padding-bottom: 40px;
}

.slider-title {
	font-size: 33px;
}

.slider-text {
	width: 100%;
}

.slide2-img {
	width: 50%;
}

.carousel.carousel-slider {
	overflow: unset;
	/* overflow-x: hidden; */
}

.carousel .slider-wrapper {
	overflow: unset;
	/* overflow-x: hidden; */
}

.carousel .slide img.slider-boy {
	width: 0%;
	position: absolute;
	right: 20px;
	bottom: -50px;
	z-index: 1000;
}

.slide2-text {
	font-size: 17px;
	margin-bottom: 13px;
}

.product-item {
	width: 25%;
}

.product-item a {
	width: 97%;
	margin: 0px auto;
	transition: 0.2s;
}

.product-item a:hover {
	width: 100%;
}

@media (min-width: 600px) {
	.carousel .slide img.slider-boy {
		width: 40%;
		position: absolute;
		right: 20px;
		bottom: -50px;
		z-index: 1000;
	}

	.slider-text {
		width: 60%;
	}
}

@media (min-width: 960px) {
	.carousel .slide img.slider-boy {
		width: 35%;
		position: absolute;
		right: 20px;
		bottom: -50px;
		z-index: 1000;
	}
}

@media (min-width: 1200px) {
	.home-slider {
		min-height: 50vh;
	}

	.slider-title {
		font-size: 45px;
	}

	.slider-text {
		width: 45%;
	}

	.slide2-text {
		font-size: 30px;
		margin-bottom: 13px;
	}

	.carousel .slide img.slider-boy {
		width: 30%;
		position: absolute;
		right: 30px;
		bottom: -50px;
		z-index: 1000;
	}
}

@media (min-width: 1200px) {
	.carousel .slide img.slider-boy {
		width: 25%;
		position: absolute;
		right: 30px;
		bottom: -50px;
		z-index: 1000;
	}
}

::-webkit-scrollbar {
	width: 8px;
}

/* ::-webkit-scrollbar-button {
	background-color: rgb(114, 185, 7);
	border-radius: 999px;
	height: 20px;
} */

::-webkit-scrollbar-track {
	background-color: rgba(229, 231, 235);
}

::-webkit-scrollbar-thumb {
	background-color: rgb(140, 142, 146);
}

.p-paginator {
	font-family: "Cera normal" !important;
	background-color: #ffffff !important;
}

.p-paginator-pages {
	background-color: #ffffff !important;
}

#bookPaginator .p-paginator-page {
	border-radius: 100% !important;
}

/*#bookPaginator .p-paginator-pages {
  display: flex;
  flex-direction: column;
}*/

#bookPaginator .p-paginator .p-dropdown {
	border-radius: 10px !important;
	appearance: none;
}

.p-sidebar-header {
	justify-content: space-between !important;
}

.p-tooltip {
	min-width: max-content;
}

.light-tooltip.p-tooltip .p-tooltip-text {
	background: rgba(248, 248, 248) !important;
	color: rgb(4, 54, 45);
}

.p-tooltip-arrow {
	color: rgba(248, 248, 248) !important;
}

.p-dialog-header {
	padding-top: 9px !important;
	padding-bottom: 6px !important;
	border-bottom: 1px solid rgb(128, 128, 128, 0.2) !important;
}

.p-card-title {
	font-size: 14px !important;
}

input:checked {
	border: 1px solid #2f8732;
	box-shadow: 1px 1px 2px #2f8732;
}

.p-password {
	width: 100% !important;
}

.p-accordion {
	border-radius: 30px !important;
}
